.service-duration-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > input {
    flex: 3;
    margin-right: 2px;
  }
  > select {
    flex: 1;
    margin-right: 2px;
  }
}
