@import './mixins';

.noselect {
  @include no-select();
}

.m-subheader .m-subheader__title {
  font-family: 'Segoe UI', 'Microsoft YaHei', Roboto, Poppins;
}

.m-subheader__complements {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.page-title-text {
  .refresh-btn {
    text-decoration: none;
    margin-left: 8px;
    color: #888;
    &:hover {
      color: #5867dd;
    }
  }
}

.m-quick-sidebar {
  width: auto;
}

.restricted-no-rights {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  &--default-content {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    color: #aaa;
    margin: 0 auto;
    text-align: center;

    .icon {
      font-size: 5rem;
      color: #ddd;
      margin-bottom: 1rem;
    }
  }
}

.m-checkbox > input.indeterminate ~ span::after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  border: 0px solid #aaa;
  background-color: #aaa;
  transform: none;
}

.m-quick-sidebar.m-quick-sidebar--on {
  right: 0 !important;
}

.m-card-user__pic {
  .drop-zone {
    border: none;
  }
}

.list-toolbar__filter {
  .store-picker {
    @include flex-row-nowrap();
    @at-root {
      &__component {
        min-width: 250px;
        margin-right: 8px;
      }
    }
  }
}

.m-checkbox > input:disabled ~ span:after {
  border-color: #bdc3d4 !important;
}

.m-badge {
  font-weight: 500;
}

.m-badge.m-badge--metal {
  color: #333;
}
