.store-media-editor {
  $item-size: 60px;

  @at-root {
    &__empty {
      button { margin: 0; padding: 0; input { cursor: pointer; } }

      @at-root {
        &-loading {
          display: flex;
          align-items: center;
          > i { margin-right: 0.25rem; font-size: 1.2rem; }
          color: #E91E63;
        }

        &-error {
          display: block;
          background-color: #EC407A;
          color: #fdfbfb;
          padding: 0.25rem 0.5rem;
          font-size: 0.85rem;
          border-radius: 3px;
          margin-bottom: 0.5rem;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__item {
      width: $item-size;
      height: $item-size;
      border: 1px solid #ddd;
      position: relative;
      margin-right: 0.55rem;
      margin-bottom: 0.55rem;
      border-radius: 0.25rem;

      > span {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.45;
        display: flex;
        align-items: center;
        justify-content: center;

        > i { color: #9E9E9E; font-size: 2.5rem; }
      }

      > strong {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        > i { font-size: 1.5rem; color: #fff; }
      }

      &--video { > span { opacity: 0.45; } }

      &--selected, &:hover {
        border-color: #aaa;
        > span { opacity: 1; }
        > em { opacity: 1; }
      }

      > em {
        background-color: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        position: absolute;
        color: red;
        right: -0.5rem;
        top: -0.5rem;
        cursor: pointer;
        opacity: 0.5;
        > i {
          color: #fff;
          font-size: 1rem;
          position: relative;
          top: 0.5px;
        }
      }
    }

    &__add-btn {
      font-size: 3rem;
      input { cursor: pointer; }
    }
  }

  &__media-detail {
    > img, > video { width: 100%; }
  }

  &__media-extra {
    padding: 0.5rem;
    background-color: #f7f7f7;
    @at-root {
      &-row {
        > label { display: block; margin-bottom: 0.25rem; }
        margin-bottom: 0.25rem;
      }
    }
  }

  &__video-thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > a {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      flex-shrink: 0;
      flex-grow: 0;
      cursor: pointer;
    }
  }

  &__thumbnail-label {
    button.btn-link {
      margin: 0;
      padding: 0;
      input { cursor: pointer; }
    }
  }
}