.inspection-site-detail {
  table {
    width: 100%;

    th {
      border: 1px solid #ddd;
      border-bottom-width: 2px;
      font-weight: bold;
      padding: 8px;
      background-color: #f1f1f1;
      font-size: 0.9rem;
    }

    td {
      border: 1px solid #ddd;
      padding: 4px 8px;
      font-size: 0.9rem;

      .m-badge {
        font-size: 0.7rem;
        width: 100%;
        white-space: nowrap;
        min-height: 0;
        line-height: 2;
        border-radius: 0.8rem;
      }

      .actions {
        white-space: nowrap;
        a {
          text-decoration: none;
          margin: 0 2px;
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
        }
      }

      &.col__item-is-pic-preferred,
      &.col__item-ifactor,
      &.col__item-pfid,
      &.col__item-vunit,
      &.col__item-vtype,
      &.col__item-tool,
      &.col__item-rstate,
      &.col__item-rpic,
      &.col__item-actions,
      &.col__opt-actions,
      &.col__opt-rpic,
      &.col__opt-is-pic-preferred {
        text-align: center;
      }
    }

    tr.row--alt td {
      background-color: #fbfbfb;
    }
  }
}
