@import '../../assets/css/mixins';

.list-toolbar__filter {
  .report-list-toolbar__store-picker-wrapper {
    .store-picker__component {
     min-width: 160px;
     max-width: 160px;
   }
  }
}

.report-detail {
  padding: 0 15px;

  > dl {
    @include flex-row-nowrap($justify: flex-start, $alignItems: flex-start);
    margin-bottom: 0;
    line-height: 2.4;
    border-bottom: 1px solid #ddd;

    dt, dd {
      margin: 0;
    }
    dt {
      width: 100px;
      margin-right: 8px;
      text-align: right;
    }

    dd {
      .kt-badge {
        line-height: 1.5;
      }
    }
  }
}

.inspection-report-result-items {
  &:first-child {
    margin-top: 1rem;
  }

  margin-bottom: 1rem;


  &__header {
    background-color: #f9f9f9;
    padding: 0.5rem 1rem;
    @include flex-row-nowrap($justify: flex-start, $alignItems: center);

    &-icon {
      margin-right: 0.5rem;
    }

    > span {
      margin-left: 0.5rem;
    }

    &--normal {
      color: #45ccb1;
    }
    &--abnormal {
      color: #ffb822;
    }
    &--urgent {
      color: #ffb822;
    }
  }
  &__list {
    .inspection-report-item {
      margin: 0;
      padding: 0.5rem;
      font-size: 0.9rem;
      border-bottom: 1px solid #eee;

      dt { font-weight: bold; margin-bottom: 0.5rem; }
      dd {
        margin-bottom: 0.35rem;
        > label {
          margin-right: 0.5rem;
        }
      }

      &__actual {
        &--normal {
          color: #45ccb1;
        }
        &--abnormal {
          color: #ffb822;
        }
        &--urgent {
          color: #ffb822;
        }
      }
    }
  }
}