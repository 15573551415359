@import './mixins';

.m-datatable.m-datatable--default > .m-datatable__table > .m-datatable__body .m-datatable__row > .m-datatable__cell > span {
  color: #222;
}

.m-datatable__cell {
  .m-switch input:checked ~ span:before,
  .m-switch input:checked ~ span:after {
    box-shadow: none;
  }
}

.m-datatable__row--clickable {
  cursor: pointer;
}

.m-datatable__row--hidden {
  display: none !important;
}

.m-datatable__action-buttons {
  @include flex_row_nowrap($justify: center);
  > button, > a {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.m-datatable__group-header {
  margin-bottom: 1px !important;
}

.m-datatable__group-header-cell {
  padding: 8px 16px;
  font-weight: bold;
  background-color: #e7e6f1;
  color: #726aca;
  .m-datatable__group-icon {
    font-size: 1.4rem;
    margin-right: 5px;
    text-decoration: none;
    color: #726aca;
    > i {
      font-weight: bold;
    }
  }
}

.m-datatable__group-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-datatable__group-header-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.m-datatable__group-header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.m-datatable__cell-detail-trigger {
  text-align: center;
  font-size: 1.2rem;
}