.drop-zone {
  padding: 0;
  border: 1px solid #ebedf2;
  text-align: center;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row-reverse;

  &--active {
    border-color: #716aca;
  }

  p {
    margin: 0;
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    flex: 1;
  }
}

.drop-zone-preview {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-remove {
    display: none;
  }

  &:hover .btn-remove {
    display: block;
  }
}
