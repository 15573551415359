.site-picker {
  $picker: &;

  margin: -15px;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  #{$picker}__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }

  #{$picker}__keyword-filter {
    padding-right: 16px;
    .form-control {
      border-radius: 16px;
    }
  }

  #{$picker}__keyword-filter input::placeholder {
    color: #ccc;
  }

  #{$picker}__category-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 5px;
  }

  #{$picker}__category-item {
    margin: 10px 10px;
    border-bottom: 2px solid transparent;
    > a {
      color: #666;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      &:hover {
        color: #716aca;
      }
    }
    &--selected {
      border-bottom: 2px solid #716aca;
      > a {
        color: #716aca;
      }
    }
  }

  #{$picker}__site-group {
    margin-bottom: 1rem;
    $group: &;
    @at-root {
      #{$group}-hd {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 15px;
        border-bottom: 1px solid #eee;
        margin-top: 8px;
        padding-bottom: 8px;
      }

      #{$group}-name {
        font-size: 1.1rem;
        color: #e91e63;
        font-weight: bold;
        margin: 0;
        > i {
          font-size: 1.2rem;
          margin-right: 2px;
        }
      }

      #{$group}-actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        > a {
          margin-left: 4px;
          text-decoration: none;
          color: #444;
          font-size: 0.9rem;
          &:hover {
            color: #2739c1;
          }
        }
      }
    }
  }

  #{$picker}__site-list {
    display: flex;
    flex-wrap: wrap;
  }

  #{$picker}__site-icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: #e2e4f3;
    margin-bottom: 8px;
    transition: all 0.2s ease-in-out;
    > img {
      width: 34px;
      height: 34px;
    }
    svg {
      width: 34px;
      height: 34px;

      path {
        fill: #5867dd;
      }
    }
  }

  #{$picker}__site {
    text-align: center;
    width: 97px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    &--selected {
      #{$picker}__site-icon {
        background-color: #5867dd;

        svg path {
          fill: #fff;
        }
      }
    }
  }

  #{$picker}__site-name {
    color: #333;
    font-size: 0.95rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @at-root {
    #{$picker}__actions {
      margin-left: 1rem;
      > a {
        font-size: 1rem;
        margin: 0 5px;
      }
    }
  }
}
