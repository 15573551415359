@import '../../../assets/css/mixins';

.org-info {
  &__detail {
    dl {
      @include flex-row-nowrap($justify: flex-start);
      font-size: 0.85em;
      margin-bottom: 3px;
    }

    dt {
      font-weight: normal;
      color: #666;
      margin: 0 8px 0 0;
    }

    dd {
      margin: 0;
    }
  }
}