.quotation-tpl-item-editor__material-list-item {
  > .m-form__section {
    background-color: #f9f9f9;
    border-radius: 0.5rem;
    margin-bottom: 1rem !important;
    padding: 1rem;
    position: relative;
    border: 1px solid #eee;
    > .m-form__section {
      margin: 0 !important;
      .form-group > label {
        display: block;
        cursor: move;
      }
      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--being-dragged {
    cursor: move;
    z-index: 100000;
    overflow: hidden;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    .m-form__section {
      background-color: #eee !important;
      margin-bottom: 0 !important;
      cursor: move;

      > .kt-section {
        margin-bottom: 0 !important;
        .form-group {
          margin-bottom: 1rem;
          cursor: move;
          > label {
            cursor: move;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .quotation-tpl-item-editor__material-item-close-button {
      display: none !important;
    }
  }
}

.quotation-tpl-item-editor__material-item {
  > .kt-section {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 1rem;
    > label {
      font-weight: normal;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
