.add-data-export-job-modal {
  .modal-dialog {
    max-width: 650px !important;
  }
}

.add-data-export-job__agent-picker {
  width: 65%;
}

.add-data-export-job__org-picker {
  width: 65%;
}

.add-data-export-job__store-picker {
  display: flex;
  flex-direction: row;
  .store-picker__component {
    width: 45%;
    margin-right: 1rem;
  }
}
