.openapi-app-authorized-stores {
  @at-root {
    &__store-picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 0.35rem;
      .store-picker {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .store-picker__component {
          width: 260px;
          margin-right: 10px;
        }
      }
    }
  }
}
