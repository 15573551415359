@mixin flex-row($justify: space-between, $wrap: wrap, $alignItems: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $alignItems;
  flex-wrap: $wrap;
}

@mixin flex-row-nowrap($justify: space-between, $alignItems: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $alignItems;
  flex-wrap: nowrap;
}

@mixin flex-col($justify: space-between, $wrap: wrap, $alignItems: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $alignItems;
  flex-wrap: $wrap;
}

@mixin flex-col-nowrap($justify: space-between, $alignItems: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $alignItems;
  flex-wrap: nowrap;
}

@mixin nowrap() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pre-ws() {
  white-space: pre-wrap;
}

@mixin active($bg: #f9f9f9) {
  transition: all 0.2s ease-in-out;
  &:active {
    background-color: #f9f9f9;
  }
}

@mixin flex-fixed-width() {
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
