@import './mixins';

.entity-editor-form__element-group {
  @include flex-row-nowrap($justify: flex-start);

  > .form-group {
    padding: 0;
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.entity-editor-form {
  .form-control-wrapper {
    position: relative;
  }
}

.form-group {
  &__label {
    &--required {
      &::after {
        content: '*';
        color: red;
        margin-left: 0.25rem;
      }
    }
  }
}
