.add-batch-job-modal {
  .modal-dialog {
    max-width: 650px !important;
  }
}

.add-batch-job__store-picker {
  display: flex;
  flex-direction: row;
  .store-picker__component {
    width: 50%;
    &:last-child {
      margin-left: 1rem;
    }
  }
}
