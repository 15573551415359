.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--brand.active {
  background: #4d44bd;
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--metal.active {
  background: #a6a7c1;
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--light.active {
  background: #e6e6e6;
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--accent.active {
  background: #0097a9;
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--focus.active {
  background: #7c0acd;
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--primary.active {
  background: #2e40d4;
  color: #fff
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--success.active {
  background: #299781;
  color: #fff
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--info.active {
  background: #0a8cf0;
  color: #fff
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--warning.active {
  background: #eea200;
  color: #111
}

.m-list-badge .m-list-badge__items a.m-list-badge__item.m-list-badge__item--danger.active {
  background: #f12143;
  color: #fff
}