.m-footer__lang {
  margin-left: 8px;
}

.m-footer__lang-icon {
  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #ddd;
    }
  }

  &:hover {
    svg path {
      fill: #333;
    }
  }
}