.m-aside-menu .m-menu__nav {
  padding: 0 !important;
}

.m-aside-menu .m-menu__nav>.m-menu__item>.m-menu__heading .m-menu__link-icon,
.m-aside-menu .m-menu__nav>.m-menu__item>.m-menu__link .m-menu__link-icon {
  text-align: center;
  width: 24px;
  font-size: 1.4rem;
}

.m-aside-menu .m-menu__nav>.m-menu__item>.m-menu__heading .m-menu__link-text,
.m-aside-menu .m-menu__nav>.m-menu__item>.m-menu__link .m-menu__link-text {
  padding-left: 8px;
}