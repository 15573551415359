.app-loading {
  transition: opacity 0.2s ease-in-out;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;

  $loading: &;

  @at-root {
    &__message {
      font-size: 0.9em;
      padding: 3px 12px;
      background-color: #fcdca2;
      border-radius: 3px;

      #{$loading}--info & {
        background-color: #36a3f7;
        color: #fff;
      }

      #{$loading}--success & {
        background-color: #34bfa3;
        color: #fff;
      }

      #{$loading}--warning & {
        background-color: #ffb822;
        color: #fff;
      }

      #{$loading}--error & {
        background-color: #f4516c;
        color: #fff;
      }
    }

    &--close {
      opacity: 0;
    }

    &--close-enter {
      opacity: 0;
    }

    &--open {
      opacity: 1;
    }

    &--open-enter {
      opacity: 1;
    }
  }
}