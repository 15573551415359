.quotation-tpl {
  $tpl: &;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 600px;

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &-msg {
      color: #f44336;
      font-size: 0.85rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      i {
        margin-right: 0.25rem;
      }
    }
    .btn {
      margin-left: 0.5rem;
    }
  }

  @mixin panel {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-hd {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      h3 {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 0;
        > a {
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }

    &-hd-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      > * {
        margin-left: 5px;
      }
    }

    &-body {
      flex: 1;
      display: flex;
    }

    &-empty,
    &-loading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }
  }

  @at-root {
    #{&}__content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      min-height: 600px;
      width: 100%;
    }

    #{$tpl}__category-panel {
      @include panel();
      width: 250px;
      background-color: #fdfdfd;
      padding-left: 0;
      padding-right: 0;

      &-loading {
        margin: 0 auto;
      }

      &-hd {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 100px;
          height: 100px;
          path {
            fill: #ddd;
          }
        }
      }
    }

    #{$tpl}__detail-panel {
      @include panel();
      flex: 1;
      flex-direction: row;
      align-items: stretch;
      border-left: 1px solid #ddd;
      padding: 0;

      &-empty,
      &-loading {
        width: 60%;
        text-align: center;
        margin: 0 auto;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 250px;
        }
      }
    }

    #{$tpl}__groups-with-subjects {
      flex: 1;
      padding: 15px;
    }

    #{$tpl}__subjects-with-items {
      margin-bottom: 1rem;
      .quotation-tpl__item-panel-hd {
        padding-top: 10px;
        padding-bottom: 10px;
        > h3 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          font-size: 1.5rem;
          i.fa-tag {
            font-size: 1.5rem;
            margin-right: 0.35rem;
          }
        }
      }
      .quotation-tpl-item__body {
        border: none;
      }
      .quotation-tpl-item__title {
        font-size: 1.2rem;
      }
    }

    #{$tpl}__subject-panel {
      @include panel();
      width: 250px;
      background-color: #f9f9f9;
      padding-left: 0;
      padding-right: 0;
      justify-content: flex-start;

      &-hd {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0.18rem;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 100px;
          height: 100px;
          path {
            fill: #ddd;
          }
        }
      }
    }

    #{$tpl}__subject-list-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-between;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0.5rem 15px;
      height: 3rem;

      &-icon {
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }

      &-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.2rem;
        font-weight: 500;
      }

      &-actions {
        display: none;
        flex-shrink: 0;
        flex-grow: 0;
        flex-wrap: nowrap;
        font-size: 1.2rem;
      }

      &:hover {
        background-color: #dcdcdc;
        #{$tpl}__subject-list-item-actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }

      &--selected {
        background-color: #716aca !important;
        color: #fff !important;
        #{$tpl}__subject-list-item-actions {
          a {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }

    #{$tpl}__category {
      margin-bottom: 1rem;

      $category: &;

      @at-root {
        &-list {
          flex: 1;
          width: 100%;
        }
      }

      #{$tpl}__group-list {
        display: none;
      }

      // &--expanded {
      // }

      &--expanded #{$tpl}__group-list {
        display: block;
      }

      &-hd {
        font-size: 1.2rem;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        margin-bottom: 5px;
        padding-right: 5px;

        > i {
          font-size: 1.2rem;
          width: 24px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        > a {
          flex-grow: 0;
          flex-shrink: 0;
          text-decoration: none;
          > i {
            font-size: 1.2rem;
            width: 24px;
          }
        }

        .form-control {
          margin-right: 5px;
        }
      }

      #{$category}-name {
        text-decoration: none;
        color: #222;
        font-weight: 500;

        &-ct {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:hover {
            #{$category}-actions > a {
              display: inline-block;
            }
          }
        }
      }

      #{$category}-actions {
        margin-left: 7px;
        > a {
          display: none;
          margin-left: 1px;
          text-decoration: none;
          opacity: 0.35;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    #{$tpl}__group {
      $group: &;

      @at-root {
        &-list {
          padding-left: 0;
        }
      }

      > div {
        padding-left: 19px;
        text-decoration: none;
        height: 3rem;
        line-height: 3rem;
        color: #333;
        cursor: default;
        display: flex;
        justify-content: space-between;
        padding-right: 8px;
        align-items: center;

        &:hover {
          background-color: #f3f3f3;
          #{$group}-remove {
            visibility: visible;
          }
        }

        > .m-badge {
          color: #888;
          height: 20px;
          line-height: 20px;
          font-style: normal;
          flex-grow: 0;
          flex-shrink: 0;
          font-size: 0.85rem;
          background-color: #b1b6d3;
          color: #fff;
          margin-left: 8px;
        }
      }

      &--selected {
        > div {
          background-color: #673ab7 !important;
          color: #fff;
        }
      }

      &--new {
        > div {
          background-color: transparent !important;
          padding-left: 37px;
          font-size: 0.85rem;
          color: #5867dd;
          cursor: pointer;
          &:hover {
            color: #2739c1;
          }
        }

        .form-control {
          margin-right: 20px;
          margin-left: 37px;
          width: 180px;
          margin-top: 1px;
          margin-bottom: 1px;
        }
      }

      #{$group}-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      #{$group}-remove {
        visibility: hidden;
        width: 16px;
        margin-right: 4px;
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }
      }

      &-detail {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    #{$tpl}__item-panel {
      @include panel();
      flex: 1;
      border-left: 1px solid #ddd;

      &-hd {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0.18rem;
      }

      &-body {
        flex-direction: column;
        padding: 10px;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 100px;
          height: 100px;
          path {
            fill: #ddd;
          }
        }
      }
    }
  }
}
