.tpl-detail {
  $detail: &;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 600px;

  @mixin panel {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-hd {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      h3 {
        font-size: 1.4rem;
        font-weight: bold;
        > a {
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }

    &-hd-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      > * {
        margin-left: 5px;
      }
    }

    &-body {
      flex: 1;
      display: flex;
    }

    &-empty,
    &-loading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }
  }
  @at-root {
    #{$detail}__category-panel {
      @include panel();
      width: 250px;
      background-color: #fdfdfd;
      padding-left: 0;
      padding-right: 0;

      &-loading {
        margin: 0 auto;
      }

      &-hd {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 100px;
          height: 100px;
          path {
            fill: #ddd;
          }
        }
      }
    }

    #{$detail}__site-panel {
      @include panel();
      flex: 1;
      border-left: 1px solid #ddd;
      padding: 15px;

      &-empty,
      &-loading {
        width: 60%;
        text-align: center;
        margin: 0 auto;
      }

      &-empty .svg--inline {
        margin-top: -4rem;
        svg {
          width: 250px;
        }
      }
    }

    #{$detail}__category {
      margin-bottom: 1rem;

      $category: &;

      @at-root {
        &-list {
          flex: 1;
          width: 100%;
        }
      }

      #{$detail}__group-list {
        display: none;
      }

      &--expanded #{$detail}__group-list {
        display: block;
      }

      &-hd {
        font-size: 1.2rem;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        margin-bottom: 5px;
        padding-right: 5px;

        > i {
          font-size: 1.2rem;
          width: 24px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        > a {
          flex-grow: 0;
          flex-shrink: 0;
          text-decoration: none;
          > i {
            font-size: 1.2rem;
            width: 24px;
          }
        }

        .form-control {
          margin-right: 5px;
        }
      }

      #{$category}-name {
        text-decoration: none;
        color: #222;
        font-weight: 500;

        &-ct {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:hover {
            #{$category}-actions > a {
              display: inline-block;
            }
          }
        }
      }

      #{$category}-actions {
        margin-left: 7px;
        > a {
          display: none;
          margin-left: 1px;
          text-decoration: none;
          opacity: 0.35;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    #{$detail}__group {
      $group: &;

      @at-root {
        &-list {
          padding-left: 0;
        }
      }

      > a {
        padding-left: 19px;
        text-decoration: none;
        height: 3rem;
        line-height: 3rem;
        color: #333;
        cursor: default;
        display: flex;
        justify-content: space-between;
        padding-right: 8px;
        align-items: center;

        &:hover {
          background-color: #f3f3f3;
          #{$group}-remove {
            visibility: visible;
          }
        }

        > .m-badge {
          color: #888;
          height: 20px;
          line-height: 20px;
          font-style: normal;
          flex-grow: 0;
          flex-shrink: 0;
          font-size: 0.85rem;
          background-color: #b1b6d3;
          color: #fff;
          margin-left: 8px;
        }
      }

      &--selected {
        > a {
          background-color: #673ab7 !important;
          color: #fff;
        }
      }

      &--new {
        > a {
          background-color: transparent !important;
          padding-left: 37px;
          font-size: 0.85rem;
          color: #5867dd;
          cursor: pointer;
          &:hover {
            color: #2739c1;
          }
        }

        .form-control {
          margin-right: 20px;
          margin-left: 37px;
          width: 180px;
          margin-top: 1px;
          margin-bottom: 1px;
        }
      }

      #{$group}-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      #{$group}-remove {
        visibility: hidden;
        width: 16px;
        margin-right: 4px;
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @at-root {
    #{$detail}__site-list {
      $site-list: &;
      display: table;
      width: 100%;
      height: 1%;

      @at-root {
        #{$site-list}-hd,
        #{$site-list}-item {
          display: table-row;
        }

        #{$site-list}-item:last-child #{$detail}__site-col {
          border-bottom: none;
        }

        #{$site-list}-item--being-dragged {
          display: table !important;
        }

        #{$site-list}-item--being-dragged #{$detail}__site-col {
          border-bottom: 1px solid #ddd !important;
        }
      }
    }

    #{$detail}__site-col {
      $col: &;

      #{$detail}__site-list-hd & {
        font-weight: bold;
        border-bottom-width: 2px;
        background-color: #f6f3fc;
        border-bottom-color: #dad7df;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      #{$detail}__site-rel-list {
        display: inline-block;
      }

      @at-root {
        #{$detail}__site-rel,
        #{$detail}__site-rel--being-dragged {
          padding: 1px 8px;
          border: 1px solid #aaa;
          margin-right: 4px;
          border-radius: 0.8rem;
          font-size: 0.9rem;
          height: 1.6rem;
          line-height: 1.6em;
          display: inline-block;
          cursor: move;
          white-space: nowrap;
          position: relative;
        }

        #{$detail}__site-rel--being-dragged {
          background-color: #673ab7;
          color: #fff;
          border-color: #350c7c;
          cursor: move !important;
          > #{$detail}__site-rel-remove {
            display: none;
          }
        }
      }

      #{$detail}__site-rel {
        vertical-align: middle;

        &:hover {
          background-color: #f2f2f2;
        }

        &--disabled {
          cursor: default !important;
        }

        #{$detail}__site-rel-name {
          color: #575962;
        }

        &-remove {
          position: absolute;
          right: -4px;
          top: -4px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #bababa;
          color: #fff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 0.6rem;
          cursor: default;
          padding: 2px;
          &:hover {
            background-color: red;
          }
        }
      }

      #{$detail}__site-rels-edit {
        font-size: 1.4rem;
        vertical-align: middle;
        margin-left: 4px;
      }

      display: table-cell;
      padding: 8px;
      border-bottom: 1px solid #ddd;
      vertical-align: middle;

      @at-root {
        #{$col}-check,
        #{$col}-default-hidden,
        #{$col}-required {
          > label.m-checkbox {
            margin-bottom: 0;
            padding-left: 0;
            width: 18px;
            height: 18px;
            > span {
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }
        }
        #{$col}-check {
          @extend #{$col};
          width: 40px;
          text-align: center;
        }

        #{$col}-sort {
          @extend #{$col};

          width: 40px;
          cursor: move;
          text-align: center;

          #{$detail}__site-list-hd & {
            cursor: default;
          }

          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #888;
            }
          }
        }

        #{$col}-name {
          width: 235px;
          @extend #{$col};
        }

        #{$col}-rels {
          width: auto;
          @extend #{$col};

          #{$detail}__site-no-rels {
            color: #777;

            > i {
              font-size: 1.2rem;
              vertical-align: middle;
              margin-right: 3px;
            }

            > span {
              vertical-align: middle;
            }
          }
        }

        #{$col}-default-hidden,
        #{$col}-required {
          width: 100px;
          text-align: center;
          @extend #{$col};
        }

        #{$col}-actions {
          @extend #{$col};
          width: 120px;
          text-align: center;

          > * {
            vertical-align: middle;
          }

          > a {
            color: #888;
            text-decoration: none;
            margin-left: 5px;
            &:hover {
              color: #716aca;
            }
            i {
              font-size: 1.6rem;
            }
          }
        }

        #{$col}-icon {
          width: 80px;
          text-align: center;
          @extend #{$col};
          > i {
            width: 40px;
            height: 40px;
          }

          > .svg--inline {
            width: 40px;
            height: 40px;
            background-color: gray;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            > svg {
              width: 32px;
              height: 32px;

              path {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .site-list__keyword-filter {
    padding-right: 16px;
    .form-control {
      border-radius: 16px;
    }
    input::placeholder {
      color: #ccc;
    }
  }
}
