.entity-editor-sidebar {
  .alert.m-alert--icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .m-alert__icon {
      padding: 1rem 1.25rem;
      [class^="fa-"], [class*=" fa-"] {
        font-size: 1em;
      }
    }

    .m-alert__text {
      padding: 1rem 1.25rem;
    }
  }
}