@import '../../../../assets/css/mixins';

$icon-color: #646c9a;
$selected-bg-color: #646c9a;

.tree {
  display: block;
  &--empty {
    padding: 16px;
    border: 1px solid #ddd;
  }
}

.tree__node {
  display: block;
}

.tree__node--expanded {
  > .tree__node-it {
    .tree__arrow {
      transform: rotate(90deg);

      &--collapse-enter {
        transform: rotate(0deg);
      }
    }
  }
}

.tree__node--selected {
  > .tree__node-it {
    .tree__node-text {
      background-color: $selected-bg-color;
      border-radius: 3px;
      > a {
        color: #fff !important;
      }
    }
  }
}

.tree__node-it {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  line-height: 2;
}

.tree__node-text {
  padding: 0px 6px;
  @include flex-row-nowrap($justify: flex-start);
  > a {
    text-decoration: none;
    color: #333;
    font-size: 1.1em;
  }
}

.tree__children {
  transition: all .2s ease-in-out;
  transform-origin: left top;
  padding-left: 20px;
  overflow: hidden;
  margin-bottom: 8px;

  &--collapsed {
    height: 0;
  }

  &--expanded {
    height: auto;
  }
}

.tree__children-empty {
  padding: 8px 16px;
  border: 1px solid #eee;
  display: inline-block;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 8px;
}

.tree__arrow {
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);

  > img {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  &--expand-enter {
    transform: rotate(90deg);
  }
}

.tree__node-icon {
  color: $icon-color;
  margin-right: 4px;
}

.tree__node-decoration {
  padding-left: 8px;
  .btn {
    margin-right: 4px;
    padding: 2px 6px;
    font-size: 0.8em;
  }
}

.tree__node-check {
  > span {
    margin-top: -6px;
    margin-bottom: 0;
  }
}